/*
 * @Author: chaixiaobu 13817847080@163.com
 * @Date: 2024-05-07 14:33:47
 * @LastEditors: chaixiaobu 13817847080@163.com
 * @LastEditTime: 2024-08-22 13:44:12
 * @FilePath: \vue3-vant-mobile\src\main.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import App from './App.vue'
import store from '@/store'
import router from '@/router'
import 'amfe-flexible'
import 'vant/lib/index.css'
import '@/styles/index.less'
import 'virtual:uno.css'
import '@/router/permission'

const app = createApp(App)

app.use(store)
app.use(router)

app.mount('#app')
