/*
 * @Author: chaixiaobu 13817847080@163.com
 * @Date: 2024-08-14 17:57:40
 * @LastEditors: chaixiaobu 13817847080@163.com
 * @LastEditTime: 2024-08-28 16:00:16
 * @FilePath: \chai-activity-h5\src\hooks\useWechat.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { ref } from 'vue'
import wx from 'weixin-js-sdk'
import { initWechat } from '@/api/user/index'
import { showToast } from 'vant'

export async function useWechat(isInclude: boolean): Promise<any> {
  const location: any = ref(null)
  const error = ref(null)

  const init = async () => {
    const url = window.location.href.split('#')[0]
    const param = { url }
    try {
      const { data } = await initWechat(param)
      
      wx.config({
        debug: false,
        appId: data.appId,
        timestamp: data.timestamp,
        nonceStr: data.nonceStr,
        signature: data.signature,
        jsApiList: ['getLocation'],
      })

      wx.error((err: any) => {
        error.value = err
      })
    } catch (e: any) {
      error.value = e
    }
  }
  const ready = () => {
    return new Promise((resolve: any) => {
      wx.ready(() => {
        wx.getLocation({
          type: 'gcj02',
          success: function (res) {
            location.value = res
            
            resolve(res)
          },
          cancel: function () {
            resolve({})
          },
          fail: function (err) {
            error.value = err
            resolve(err)
          },
        })
      })
    })
  }

  // 使用示例
  const environment = detectBrowserEnvironment()
  if (environment === 'WeChat Browser') {
    init()
  } else {
    if (!isInclude) {
      return Promise.resolve({
        location,
        error,
        ready,
        isWechatBrowser: environment === 'WeChat Browser',
      })
    }
    return new Promise((resolve) => {
      showToast({
        message: '无法获取定位，请在微信浏览器中打开',
        duration: 2000,
        forbidClick: true,
        type: 'fail',
        onClose() {
          resolve({
            location,
            error,
            ready,
            isWechatBrowser: environment === 'WeChat Browser',
          })
        },
      })
    })
  }
  return new Promise((resolve) => {
    resolve({
      location,
      error,
      ready,
      isWechatBrowser: environment === 'WeChat Browser',
    })
  })
}

export function detectBrowserEnvironment(): string {
  const userAgent = navigator.userAgent.toLowerCase()

  // 检测是否为微信浏览器
  const isWeChat = /micromessenger/.test(userAgent)

  // 检测是否为企业微信
  const isWorkWeChat = /wechatwork/.test(userAgent)

  // 检测是否为移动设备
  const isMobile = /mobile/.test(userAgent)

  if (isWeChat) {
    if (isWorkWeChat) {
      return 'WeChat Work'
    } else {
      return 'WeChat Browser'
    }
  } else if (isMobile) {
    return 'Mobile Browser'
  } else {
    return 'Desktop Browser'
  }
}
